/* eslint-disable import/prefer-default-export */
import { format, differenceInDays } from 'date-fns'
import * as constants from '@connections/constants'

const {
    BOOKING_TYPE_FLIGHT: FLIGHT,
    FLIGHT_MODE_ROUND_TRIP: ROUND_TRIP,
} = constants

export function getFlightMetaDataFromResultsUrl(resultsUrl, locale) {
    const searchString = resultsUrl.split('?')[1]
    const params = Object.fromEntries(new URLSearchParams(searchString))

    const { fareType, mode } = params
    const adults = parseInt(params.adults, 10)
    const infants = parseInt(params.infants, 10)
    const children = parseInt(params.children, 10)
    const to = params.to.split('_')[0].split('-')[1]
    const from = params.from.split('_')[0].split('-')[1]
    const departureString = params.departure.split('_')[0]
    const returnString = params.return && params.return.split('_')[0]
    const departureDate = new Date(departureString)
    const returnDate = returnString ? new Date(returnString) : null

    return {
        to,
        fareType,
        language: locale,
        flightMode: mode,
        bookingType: FLIGHT,
        numberOfAdults: adults,
        numberOfInfants: infants,
        numberOfChildren: children,
        bookingDate: format(new Date(), 'dd-MM-yyyy'),
        numberOfPassengers: adults + infants + children,
        returnDate: returnDate ? format(returnDate, 'dd-MM-yyyy') : null,
        departureDate: departureDate ? format(departureDate, 'dd-MM-yyyy') : null,
        duration: returnDate ? differenceInDays(returnDate, departureDate) : null,
        bookingWindow: departureDate ? differenceInDays(departureDate, new Date()) + 1 : null,
        route: mode === ROUND_TRIP ? `${from}-${to}-${to}-${from}` : params.from.split('_').reduce((acc, fromCodeFull, index) => {
            const fromCode = fromCodeFull.split('-')[1]
            const toCode = params.to.split('_')[index].split('-')[1]

            return `${acc}${index === 0 ? '' : '-'}${fromCode}-${toCode}`
        }, '')
    }
}

import { usePathname } from 'next/navigation'
import { getFullLocaleFromPathname, getSimpleLocaleFromPathname } from '@connections/utils'

export default function usePathnameLocale(isFullLocale = false) {
    const pathname = usePathname()
    if (isFullLocale) {
        return getFullLocaleFromPathname(pathname)
    }
    return getSimpleLocaleFromPathname(pathname)
}

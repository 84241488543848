import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { nl, fr, enUS } from 'date-fns/locale'
import usePathnameLocale from './usePathnameLocale'

const getLocalData = (locale) => {
    if (locale === 'fr-be') {
        return fr
    }
    if (locale === 'en') {
        return enUS
    }
    return nl
}

const useFormatDate = (options = {}) => {
    const locale = usePathnameLocale(true)
    return (date, formatString) => {
        const { inTimeZone = false, ...rest } = options
        const finalOptions = {
            locale: getLocalData(locale),
            ...rest
        }
        if (inTimeZone) {
            return formatInTimeZone(date, 'UTC', formatString, finalOptions)
        }
        return format(
            date,
            formatString,
            finalOptions
        )
    }
}

export default useFormatDate

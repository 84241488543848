import * as constants from '@connections/constants'

const {
    CLASS_TYPE_ECONOMY: ECONOMY,
    DEFAULT_CHECKED_BAGGAGE_WEIGHT,
    FLIGHT_MODE_MULTI_CITY: MULTI_CITY,
    CHARGEABLE_INDICATOR_INCLUDED: INCLUDED,
} = constants

export function getCheckedPieces(descriptionString) {
    // Free Checked Bag
    const freeCheckedMatch = descriptionString.match(/freechecked/g)
    if (freeCheckedMatch) {
        return freeCheckedMatch.length
    }
    // 1 X ...
    const oneXMatch = descriptionString.match(/\d{1}?\s*x/i)
    if (oneXMatch) {
        return parseInt(oneXMatch[0].replace(/x/i, ''), 10)
    }
    // 1 Piece ...
    const onePieceMatch = descriptionString.match(/\d{1}?\s*piece/i)
    if (onePieceMatch) {
        return parseInt(onePieceMatch[0].replace(/piece/i, ''), 10)
    }
    // 1 Checked bag, 1 Checked in baggage
    const checkedPieceMatch = descriptionString.match(/\d{1}?\s*checked/i)
    if (checkedPieceMatch) {
        return parseInt(checkedPieceMatch[0].replace(/checked/i, ''), 10)
    }
    // X Bags
    const xBagsMatch = descriptionString.match(/\d{1}?\s*bags/i)
    if (xBagsMatch) {
        return parseInt(xBagsMatch[0].replace(/bags/i, ''), 10)
    }

    return 1
}

export function getBagServiceProperties(description, chargeableIndicator) {
    const content = description.toLowerCase()
    const isCabin = content.includes('cabin')
        || content.includes('hand')
        || content.includes('golf')
        || content.includes('small')
        || content.includes('laptop')
        || content.includes('carryon')
        || content.includes('personal')
        || content.includes('carry on')
        || content.includes('carry-on')
    const isChecked = content.includes('checked') || !isCabin
    const isForAllowance = content.includes('for') && content.includes('allowance')
    const isIncluded = chargeableIndicator === INCLUDED && !isForAllowance

    return {
        isCabin,
        isChecked,
        isIncluded,
        isForAllowance,
    }
}

export function getBagDescriptionWeight(bagDescription) {
    const weightMatch = bagDescription.match(/\d{1,2}?\s*kg/gi) || bagDescription.match(/\d{1,2}?\s*kilogram/gi)
    const weight = weightMatch ? weightMatch[0].replace(/kg/gi, '') : DEFAULT_CHECKED_BAGGAGE_WEIGHT

    return parseInt(weight, 10)
}

export function hasPossibleBrandedFares(fare, mode, infants, cabinClass) {
    // Only get branded fares for non low cost, non multi city bookings and economy class
    if (fare.isLowCost || mode === MULTI_CITY || cabinClass !== ECONOMY) {
        return false
    }
    // There seem to be a number of issues with branded fares for infants so Connections decided they just don't want to offer them in this case.
    if (infants > 0) {
        return false
    }
    const [{ brandId: firstBrandId }] = fare.priceBreakdown[0].fareInformation
    const hasSameBrandForEverySegment = fare.priceBreakdown.every(({ fareInformation }) => {
        const hasSameBrandForEverySegmentGroup = fareInformation.every(({ brandId }) => !!brandId && brandId === firstBrandId)
        return hasSameBrandForEverySegmentGroup
    })
    // Branded fares can have different brands for different segments. This is very confusing for the customer so we don't show these.
    if (!hasSameBrandForEverySegment) {
        return false
    }
    const [{ carrier: firstCarrier }] = fare.airRoutes[0].segments
    const hasSameCarrierForEverySegment = fare.airRoutes.every(({ segments }) => segments.every(({ carrier }) => carrier === firstCarrier))
    // Connections does not want to offer branded fares for trips with multiple carriers
    if (!hasSameCarrierForEverySegment) {
        return false
    }

    return true
}

import * as constants from '@connections/constants'

const {
    DEFAULT_LANGUAGE_WEB: DEFAULT_LANGUAGE,
    DEFAULT_LANGUAGE_WEB_SIMPLE: DEFAULT_LANGUAGE_SIMPLE,
} = constants

export const getSimpleLocale = (fullLocale) => {
    if (fullLocale === '') {
        return DEFAULT_LANGUAGE_SIMPLE
    }
    const [locale] = fullLocale.split('-')
    return locale
}

export const getFullLocale = (locale) => {
    if (locale === '') {
        return DEFAULT_LANGUAGE
    }
    if (locale === 'nl' || locale === 'fr') {
        return `${locale}-be`
    }
    return locale
}

export const getFullLocaleFromPathname = (pathname) => {
    const fullLocale = pathname.split('/')[1]
    if (!fullLocale) {
        return DEFAULT_LANGUAGE
    }
    return fullLocale
}

export const getSimpleLocaleFromPathname = (pathname) => {
    const fullLocale = pathname.split('/')[1]
    if (!fullLocale) {
        return DEFAULT_LANGUAGE_SIMPLE
    }
    const [locale] = fullLocale.split('-')
    return locale
}
